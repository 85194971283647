import React from 'react';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { ApolloProvider } from '@apollo/client';
import { GlobalStyles } from '@avangard/ui/theme';

import { createApolloClient } from '@lib/apollo';
import { renderRoutes, renderRedirects } from '@lib/routing';
import { routes as configRoutes, redirects as configRedirects } from '@config/routes';
import { globalStyles } from '@modules/layout/styled';

const history = createHistory(window as any);
const apolloClient = createApolloClient();

const App = (): React.ReactElement => {
    const routes = Object.values(configRoutes);
    const redirects = Object.values(configRedirects);

    return (
        <ApolloProvider client={apolloClient}>
            <GlobalStyles styles={globalStyles} />
            <LocationProvider history={history}>
                <Router basepath='/'>
                    {renderRoutes(routes)}
                    {renderRedirects(redirects)}
                </Router>
            </LocationProvider>
        </ApolloProvider>
    );
};

export { App };
