import { gql } from '@apollo/client';

export const onStandLessonsScheduleUpdateSubscription = gql`
    subscription OnStandLessonsScheduleUpdate($company: Int!) {
        onStandLessonsScheduleUpdate(company: $company) {
            platoon
            items {
                id
                subject
                place
                start
                end
            }
        }
    }
`;
