import styled from 'styled-components';

type ScheduleItemProps = {
    subject: string;
    place: string;
    start: string;
    end: string;
    color: string;
};

const Root = styled.fieldset`
    width: 100%;
    max-width: 1020px;
    padding: 0px 20px 20px 20px;
    background-color: transparent;
    border: 4px solid #fff;
    border-radius: 12px;
`;

const Time = styled.legend`
    position: relative;
    padding: 0 30px;
    color: ${p => p.color || '#fff'};
    font-size: 6rem;
    letter-spacing: 0.5rem;
    transform: translateY(-10%);
`;

const Content = styled.div`
    display: flex;
    width: 980px;
    min-height: 96px;
    flex-direction: row;
    gap: 2em;
    align-items: center;

    > p {
        vertical-align: bottom;
        overflow: hidden;
    }
`;

const Subject = styled.p`
    width: 70%;
    font-size: 5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Place = styled.p`
    display: -webkit-box;
    width: 30%;
    font-size: 4rem;
    text-align: right;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const ScheduleItem = (props: ScheduleItemProps) => {
    const { subject, place, color, start, end } = props;

    const displayTime = `${start} - ${end}`;

    return (
        <Root>
            <Time color={color}>{displayTime}</Time>
            <Content>
                <Subject>{subject}</Subject>
                <Place>{place}</Place>
            </Content>
        </Root>
    );
};

export { ScheduleItem };
