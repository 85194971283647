import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useParams } from '@reach/router';

import { Loader } from '@modules/layout/moleculas';
import { useCompanyDetails, useStandLessonsSchedule } from '@modules/schedule/hooks';
import { ScheduleItem, ScheduleVideo } from '@modules/schedule/organisms';

import type { StandLessonsScheduleEntity } from '@modules/schedule/entities';
import { autoScroll, AutoScroll } from '@lib/auto-scroll';

const Root = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 60, 0.8);

    .fade-enter-active,
    .fade-enter-done {
        opacity: 1;
        visibility: visible;
        transition-delay: 1000ms;
    }

    .fade-exit-active,
    .fade-exit-done {
        opacity: 0;
        visibility: hidden;
    }
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px auto;
    color: #fff;
    align-items: center;
    font-weight: bold;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: all 1000ms ease-in;
`;

const ScrollWrapper = styled.div`
    max-height: 1704px;
    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
        display: none;
    }

    > * {
        margin-bottom: 50px;

        &:first-child {
            margin-top: 50px;
        }
    }
`;

const Title = styled.p`
    margin: 0 auto;
    font-size: 8rem;
    word-spacing: 40px;
`;

const transitionTimingTimeout = { enter: 2000, exit: 1000 };

const SchedulePage = () => {
    const params = useParams();
    const company = +params.company;

    const prevLessonsSchedule = React.useRef<(StandLessonsScheduleEntity | undefined)[]>([]);
    const schedulesItemsRef = React.useRef<
        Record<
            number,
            {
                autoScrollInstance: AutoScroll | null;
                element: HTMLDivElement | null;
            }
        >
    >({});

    const setScheduleItemsRef = (element: HTMLDivElement | null, platoon: number) => {
        if (schedulesItemsRef.current[platoon]) {
            schedulesItemsRef.current[platoon].element = element;
        } else {
            schedulesItemsRef.current[platoon] = { autoScrollInstance: null, element };
        }
    };

    const [currentIndex, setCurrentIndex] = React.useState<number | null>(null);

    const { video, color } = useCompanyDetails({ company });

    const { lessonsSchedule, count: lessonsScheduleCount, loading } = useStandLessonsSchedule({
        variables: { company },
    });

    React.useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        const firstLessonsScheduleItems = lessonsSchedule[0]?.items ?? [];

        if (firstLessonsScheduleItems.length > 0 && currentIndex === null) {
            setCurrentIndex(0);

            intervalId = setInterval(() => {
                setCurrentIndex(prevState => {
                    if (prevState === null) {
                        return prevState;
                    }

                    if (prevState + 1 === lessonsScheduleCount) {
                        return 0;
                    }

                    return prevState + 1;
                });
            }, 15000);
        }

        return () => {
            if (firstLessonsScheduleItems.length === 0 && intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [lessonsSchedule]);

    React.useEffect(() => {
        if (lessonsSchedule.length > 0) {
            lessonsSchedule.forEach((schedule, idx) => {
                const prevScheduleItems = prevLessonsSchedule.current[idx]?.items ?? [];
                const scheduleItems = schedule?.items ?? [];

                if (prevScheduleItems.length === scheduleItems.length) {
                    return;
                }

                const schedulePlatoon = schedule?.platoon ?? 0;
                const scheduleItemsRef = schedulesItemsRef.current[schedulePlatoon];

                if (scheduleItemsRef.element && scheduleItems.length > 7) {
                    if (!scheduleItemsRef.autoScrollInstance) {
                        scheduleItemsRef.autoScrollInstance = autoScroll.create(scheduleItemsRef.element, {
                            plane: 'vertical',
                            startDelay: 4000,
                            endDelay: 2000,
                            pauseDelay: transitionTimingTimeout.exit,
                        });
                    }
                }
            });

            prevLessonsSchedule.current = lessonsSchedule;
        }
    }, [lessonsSchedule]);

    React.useEffect(() => {
        let autoScrollInstance: AutoScroll | null = null;

        if (currentIndex !== null) {
            autoScrollInstance = schedulesItemsRef.current[currentIndex + 1]?.autoScrollInstance;
        }

        if (autoScrollInstance) {
            autoScrollInstance.startScroll(true);
        }

        return () => {
            if (autoScrollInstance) {
                autoScrollInstance.pauseScroll();
            }
        };
    }, [currentIndex]);

    React.useEffect(
        () => () => {
            if (schedulesItemsRef.current) {
                Object.values(schedulesItemsRef.current).forEach(scheduleItemRef => {
                    if (scheduleItemRef.autoScrollInstance) {
                        scheduleItemRef.autoScrollInstance.destroy();
                    }
                });
            }
        },
        [],
    );

    if (loading) {
        return (
            <Root>
                <Loader size={250} color='secondary' />
            </Root>
        );
    }

    if (lessonsScheduleCount === 0) {
        return (
            <Root>
                <ScheduleVideo video={video} />
            </Root>
        );
    }

    return (
        <Root>
            <ScheduleVideo video={video} />

            {lessonsSchedule.map((lessonSchedule, idx) => {
                if (!lessonSchedule) {
                    return null;
                }

                const platoon = lessonSchedule.platoon;

                return (
                    <CSSTransition
                        timeout={transitionTimingTimeout}
                        classNames='fade'
                        key={platoon}
                        in={idx === currentIndex}
                    >
                        <Container>
                            <Title>{`${company} рота ${platoon} взвод`}</Title>

                            <ScrollWrapper ref={element => setScheduleItemsRef(element, platoon)}>
                                {lessonSchedule.items.map(item => {
                                    const { id, subject, place, start, end } = item;

                                    return (
                                        <ScheduleItem
                                            key={id}
                                            color={color}
                                            subject={subject}
                                            place={place}
                                            start={start}
                                            end={end}
                                        />
                                    );
                                })}
                            </ScrollWrapper>
                        </Container>
                    </CSSTransition>
                );
            })}
        </Root>
    );
};

export { SchedulePage };
