import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { StandLessonsScheduleItem } from '@modules/types/graphql';

export type Entity = StandLessonsScheduleItem | null;

@DecribeEntity('StandLessonsScheduleItemEntity')
class StandLessonsScheduleItemEntity extends BaseEntity {
    id: string;
    subject: string;
    place: string;
    start: string;
    end: string;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.subject = entity?.subject ?? '';
        this.place = entity?.place ?? '';
        this.start = entity?.start ?? '';
        this.end = entity?.end ?? '';
    }
}

export { StandLessonsScheduleItemEntity };
