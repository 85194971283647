import firstCompanyVideo from './videos/first.mp4';
import secondCompanyVideo from './videos/second.mp4';
import thirdCompanyVideo from './videos/third.mp4';
import fourthCompanyVideo from './videos/fourth.mp4';
import fifthCompanyVideo from './videos/fifth.mp4';

const backgroundVideos: string[] = [
    firstCompanyVideo,
    secondCompanyVideo,
    thirdCompanyVideo,
    fourthCompanyVideo,
    fifthCompanyVideo,
];

export { backgroundVideos };
