import { StandLessonsSchedule } from './../../types/graphql';
import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { StandLessonsScheduleItemEntityList } from './stand-lessons-schedule-item.entity-list';

import { StandLessonsScheduleItemEntity } from './stand-lessons-schedule-item.entity';

export type Entity = StandLessonsSchedule | null;

@DecribeEntity('StandLessonsScheduleEntity')
class StandLessonsScheduleEntity extends BaseEntity {
    platoon: number;

    @RelationList(() => StandLessonsScheduleItemEntityList)
    items: StandLessonsScheduleItemEntity[];

    constructor(entity: Entity) {
        super(entity);

        this.platoon = entity?.platoon ?? 1;
    }
}

export { StandLessonsScheduleEntity };
