const globalStyles = `
    @font-face {
        font-family: Exo2;
        font-weight: 400;
        font-display: swap;
        font-style: normal;
        src: url('/fonts/Exo2-Light.eot');
        src: url('/fonts/Exo2-Light.eot?#iefix') format("embedded-opentype"), url('/fonts/Exo2-Light.woff2') format("woff2"), url('/fonts/Exo2-Light.ttf') format("truetype")
    }

    * {
        box-sizing: border-box;
    }

    html, body { height: 100%; }

    body {
        background: none;
        font-family: Exo2, Colibri, Arial, sans-serif;
    }
`;

export { globalStyles };
