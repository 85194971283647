import { EntityList } from '@lib/entity';
import { StandLessonsScheduleItemEntity } from './stand-lessons-schedule-item.entity';

import type { Entity } from './stand-lessons-schedule-item.entity';

class StandLessonsScheduleItemEntityList extends EntityList<StandLessonsScheduleItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StandLessonsScheduleItemEntity, items, count);
    }
}

export { StandLessonsScheduleItemEntityList };
