import styled from 'styled-components';
import BackgroundVideo from 'react-background-video-player';

type ScheduleVideoProps = {
    video: string;
};

const Video = styled(BackgroundVideo)`
    position: fixed;
    width: 100%;
    height: auto;
    z-index: -3;
`;

const ScheduleVideo = (props: ScheduleVideoProps) => {
    const { video } = props;

    return <Video src={video} autoPlay={true} loop={true} />;
};

export { ScheduleVideo };
