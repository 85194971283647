import React from 'react';
import { Redirect } from '@reach/router';

import { routes } from '@config/routes';

const IndexPage = (): React.ReactElement | null => {
    return <Redirect noThrow to={routes.schedule.path} />;
};

export { IndexPage };
