import { EntityList } from '@lib/entity';
import { StandLessonsScheduleEntity } from './stand-lessons-schedule.entity';

import type { Entity } from './stand-lessons-schedule.entity';

class StandLessonsScheduleEntityList extends EntityList<StandLessonsScheduleEntity | undefined> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StandLessonsScheduleEntity, items, count);
    }
}

export { StandLessonsScheduleEntityList };
