export type AutoScrollOptions = {
    speed?: number;
    bounced?: boolean;
    startDelay?: number;
    endDelay?: number;
    pauseDelay?: number;
};

const defaultOptions = {
    speed: 1,
    bounced: true,
    startDelay: 2000,
    endDelay: 2000,
    pauseDelay: 0,
};

abstract class AutoScroll {
    abstract isStartScroll(): boolean;
    abstract isEndScroll(): boolean;
    abstract forwardScroll(): void;
    abstract backwardScroll(): void;

    frameRate = 1000 / 60;

    element: HTMLElement;
    options: Required<AutoScrollOptions>;
    direction: 'forward' | 'backward';

    scrollIntervalId: NodeJS.Timeout | null = null;
    startScrollTimeoutId: NodeJS.Timeout | null = null;
    pauseScrollTimeoutId: NodeJS.Timeout | null = null;

    constructor(element: HTMLElement, options?: AutoScrollOptions) {
        this.element = element;

        this.direction = 'forward';

        this.options = {
            ...defaultOptions,
            ...options,
        };

        this._init();
    }

    isForwardDirection() {
        return this.direction === 'forward';
    }

    isBackwardDirection() {
        return this.direction === 'backward';
    }

    run() {
        this.scrollIntervalId = setInterval(() => {
            if (this.isForwardDirection()) {
                this.forwardScroll();
            } else if (this.isBackwardDirection()) {
                this.backwardScroll();
            }

            this.updateDirection();
        }, this.frameRate);
    }

    updateDirection() {
        if (this.isStartScroll()) {
            this.direction = 'forward';

            this.restartScroll();

            return;
        }

        if (this.isEndScroll()) {
            this.direction = 'backward';

            if (this.options.bounced) {
                this.restartScroll();
            }

            return;
        }
    }

    restartScroll() {
        this._pause(0);
        this.startScroll();
    }

    startScroll(reset?: boolean) {
        const delay = this.isForwardDirection() ? this.options.startDelay : this.options.endDelay;

        this._start(delay, reset);
    }

    pauseScroll() {
        const delay = this.options.pauseDelay;

        this._pause(delay);
    }

    resetScroll() {
        this._reset();
    }

    destroy() {
        this._clearTimers();
        this._destroyEvents();
    }

    // private methods
    _init() {
        this._initEvents();
    }

    _initEvents() {
        this.element.addEventListener('mouseenter', this._handleMouseEnter);
        this.element.addEventListener('mouseleave', this._handleMouseLeave);
    }

    _destroyEvents() {
        this.element.removeEventListener('mouseenter', this._handleMouseEnter);
        this.element.removeEventListener('mouseleave', this._handleMouseLeave);
    }

    _clearTimers() {
        this._clearStartScroll();
        this._clearPauseScroll();
        this._clearScrollInterval();
    }

    _handleMouseEnter = () => {
        this.pauseScroll();
    };

    _handleMouseLeave = () => {
        this._start(0);
    };

    _start(delay: number, reset?: boolean) {
        if (reset) {
            this._reset();
        }

        this._clearStartScroll();
        this.startScrollTimeoutId = setTimeout(() => this.run(), delay);
    }

    _pause(delay: number) {
        this._clearPauseScroll();
        this.pauseScrollTimeoutId = setTimeout(() => this._stop(), delay);
    }

    _stop() {
        this._clearScrollInterval();
    }

    _reset() {
        this.element.scrollTop = 0;
    }

    _clearStartScroll() {
        if (this.startScrollTimeoutId) {
            clearTimeout(this.startScrollTimeoutId);

            this.startScrollTimeoutId = null;
        }
    }

    _clearPauseScroll() {
        if (this.pauseScrollTimeoutId) {
            clearTimeout(this.pauseScrollTimeoutId);

            this.pauseScrollTimeoutId = null;
        }
    }

    _clearScrollInterval() {
        if (this.scrollIntervalId) {
            clearInterval(this.scrollIntervalId);

            this.scrollIntervalId = null;
        }
    }
}

export { AutoScroll };
