import { colors } from '@modules/ui/core';
import { backgroundVideos } from '@modules/schedule/data';

type UseCompanyDetailsOptions = {
    company: number;
};

const useCompanyDetails = (props: UseCompanyDetailsOptions) => {
    const { company } = props;

    const companyIdx = company - 1;

    const result = {
        video: backgroundVideos[companyIdx],
        color: colors[companyIdx],
    };

    return result;
};

export { useCompanyDetails };
