import { useEntity } from '@lib/entity';
import { SubscriptionHookOptions, useSubscription } from '@apollo/client';

import { onStandLessonsScheduleUpdateSubscription } from '@modules/schedule/graphql';
import { StandLessonsScheduleEntityList } from '@modules/schedule/entities';

import type {
    OnStandLessonsScheduleUpdateSubscriptionType,
    OnStandLessonsScheduleUpdateSubscriptionVariables,
} from '@modules/types/graphql';

const useStandLessonsSchedule = (
    options: SubscriptionHookOptions<
        OnStandLessonsScheduleUpdateSubscriptionType,
        OnStandLessonsScheduleUpdateSubscriptionVariables
    > = {},
) => {
    const subscriptionResult = useSubscription<
        OnStandLessonsScheduleUpdateSubscriptionType,
        OnStandLessonsScheduleUpdateSubscriptionVariables
    >(onStandLessonsScheduleUpdateSubscription, options);

    const { entities: lessonsSchedule, count } = useEntity(
        () => new StandLessonsScheduleEntityList(subscriptionResult.data?.onStandLessonsScheduleUpdate ?? null),
        [subscriptionResult.data],
    );

    const result = {
        lessonsSchedule,
        count,
        ...subscriptionResult,
    };

    return result;
};

export { useStandLessonsSchedule };
